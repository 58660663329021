import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
// import { thunk } from 'redux-thunk'

import { userReducer } from "./user.slice";
import { authReducer } from "./auth.slice";
import { metadataReducer } from "./metadata.slice";
import { adminReducer } from "./admin.slice";
import { teamReducer } from "./team.slice";
import { memberReducer } from "./member.slice";
import { mspReducer } from "./msp.slice";
import { tenantReducer } from "./tenant.slice";
// import { cveReducer } from './cve.slice';
import { notificationsReducer } from "./notifications.slice";
import { teamoverviewReducer } from "./teamoverview.slice";
import { overviewReducer } from "./overview.slice";
import { onboardReducer } from "./onboard.slice";
import { paginateReducer } from "./paginate.slice";
import { loadingReducer } from "./loading.slice";

const reducers = combineReducers({
    auth: authReducer,
    user: userReducer,
    admin: adminReducer,
    metadata: metadataReducer,
    team: teamReducer,
    teamoverview: teamoverviewReducer,
    overview: overviewReducer,
    msp: mspReducer,
    tenant: tenantReducer,
    member: memberReducer,
    notifications: notificationsReducer,
    onboard: onboardReducer,
    paginate: paginateReducer,
    loading: loadingReducer,
    // cve: cveReducer
});

const persistConfig = {
    key: "shadowdetect",
    whitelist: ["auth", "admin", "user", "onboard", "paginate"],
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {},
            serializableCheck: false,
        }),
    devTools: process.env.NODE_ENV !== "production",
});

export default store;

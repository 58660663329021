import { createSlice } from "@reduxjs/toolkit";

const onboardSlice = createSlice({
    name: "onboard",
    initialState: false,
    reducers: {
        removeOnboard: (state, action) => {
            return false;
        },

        ssidOnboard: (state, action) => {
            return {
                ...state,
                s: action.payload.ssid,
                p: action.payload.ssidpass,
            };
        },
        userOnboard: (state, action) => {
            return {
                ...state,
                email: action.payload.email,
                password: action.payload.password,
                company_name: action.payload.company_name,
            };
        },
    },
});

export const onboardReducer = onboardSlice.reducer;

export const { removeOnboard, ssidOnboard, userOnboard } = onboardSlice.actions;

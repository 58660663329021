import { createSlice } from "@reduxjs/toolkit";

const teamSlice = createSlice({
    name: "team",
    initialState: false,
    reducers: {
        addTeamdata: (state, action) => {
            return action.payload;
        },
        editTeamdata: (state, action) => {
            if (state?.data) {
                const index = state?.data.findIndex(
                    (item) =>
                        item.id == action.payload.id &&
                        item.metadata_id == action.payload.metadata_id
                );

                if (index >= 0) {
                    state.data[index].name = action.payload.name;
                    state.data[index].os_vendor = action.payload.os_vendor;
                    state.data[index].os_model = action.payload.os_model;
                    state.data[index].os_version = action.payload.os_version;
                    state.data[index].os_patch_level =
                        action.payload.os_patch_level;
                    state.data[index].os_arch = action.payload.os_arch;
                    state.data[index].auto_updates_enabled =
                        action.payload.auto_updates_enabled;
                    state.data[index].hw_vendor = action.payload.hw_vendor;
                    state.data[index].hw_model = action.payload.hw_model;
                    state.data[index].hw_version = action.payload.hw_version;
                    state.data[index].type = action.payload.type;
                }
            } else {
                return state;
            }
        },

        editTeamVulndata: (state, action) => {
            if (state?.data) {
                const index = state?.data.findIndex(
                    (item) =>
                        item.id == action.payload.id &&
                        item.metadata_id == action.payload.metadata_id
                );
                if (index >= 0) {
                    // delete all for that mac address and metadata id, then add the new stuff to the end, but only for theis sub-prop
                    state.data[index].vulndata = action.payload.vulndata;
                }
            } else {
                return state;
            }
        },
        removeTeamVulndata: (state, action) => {
            if (state?.data) {
                const index = state?.data.findIndex(
                    (item) =>
                        item.id == action.payload.id &&
                        item.metadata_id == action.payload.metadata_id
                );
                if (index >= 0) {
                    // delete all for that mac address and metadata id, then add the new stuff to the end, but only for theis sub-prop
                    state.data[index].vulndata = false;
                }
            } else {
                return state;
            }
        },

        editSingleTeamVulndata: (state, action) => {
            if (state?.data) {
                // delete all for that mac address and metadata id, then add the new stuff to the end, but only for theis sub-prop
                const index = state?.data.findIndex(
                    (item) =>
                        item.id == action.payload?.device_data_id &&
                        item.metadata_id == action.payload?.metadata_id
                );

                if (index >= 0) {
                    // we have our device now
                    // but we need to find the matching vuln now
                    const vulnindex = state.data[index].vulndata.findIndex(
                        (item) => item.cve_meta_id == action.payload.cve_meta_id
                    );

                    if (vulnindex >= 0) {
                        (state.data[index].vulndata[vulnindex].ignored =
                            action.payload.ignored ?? null),
                            (state.data[index].vulndata[vulnindex].resolved =
                                action.payload.resolved ?? null),
                            (state.data[index].vulndata[vulnindex].comment =
                                action.payload.comment ?? null);
                    }

                    // we have to add it to the vulndatauser too
                    const vulndatauserindex = state.data[
                        index
                    ].vulndatauser.findIndex(
                        (item) => item.cve_meta_id == action.payload.cve_meta_id
                    );

                    if (vulndatauserindex >= 0) {
                        state.data[index].vulndatauser[vulnindex] =
                            action.payload;
                    } else {
                        state.data[index].vulndatauser = [
                            ...state.data[index].vulndatauser,
                            action.payload,
                        ];
                    }
                }
            }
            return state;
        },
        removeTeamdata: (state, action) => {
            return false;
        },
    },
});

export const teamReducer = teamSlice.reducer;

export const {
    addTeamdata,
    editTeamdata,
    editTeamVulndata,
    removeTeamVulndata,
    editSingleTeamVulndata,
    removeTeamdata,
} = teamSlice.actions;

import { addAuth } from "../redux/auth.slice";
import store from "../redux/store";
import { addUser, editUser, updateOnboard } from "../redux/user.slice";
import { addAdmin, editAdmin } from "../redux/admin.slice";
import { removeMetadata } from "../redux/metadata.slice";
import { addTeamdata } from "../redux/team.slice";
import { Capacitor } from "@capacitor/core";
import { sd_api_get, sd_api_post, sd_api_post_admin } from "./sd_api";
import { addMspdata } from "../redux/msp.slice";
import { addTenantdata } from "../redux/tenant.slice";
import { addMember } from "../redux/member.slice";
import { userOnboard } from "../redux/onboard.slice";
import { removeLoading } from "../redux/loading.slice";

/**
 *
 * @param {*} email
 * @param {*} password
 * @param {*} tenant
 * @returns
 */
export const login = async (email, password, tenant) => {
    try {
        const response = await sd_api_post(
            "/api/v1/login",
            {
                email: email.toLowerCase(),
                password: password,
                device_name: Capacitor.getPlatform(),
            },
            tenant
        );
        if (response.status == 200) {
            if (response.data.two_factor) {
                store.dispatch(
                    addAuth({
                        two_factor: true,
                        xtenant: tenant,
                    })
                );
            } else {
                // lets add to our onboarded thing

                if (response.data.user.onboarded == "0") {
                    store.dispatch(
                        userOnboard({
                            email: email,
                            password: password,
                            company_name: tenant,
                        })
                    );
                }

                store.dispatch(addAuth(response.data.auth));

                if (
                    check_is_user(response.data.user) ||
                    check_is_manager(response.data.user)
                ) {
                    store.dispatch(addUser(response.data.user));
                }
                if (
                    check_is_admin(response.data.user) ||
                    check_is_msp(response.data.user)
                ) {
                    store.dispatch(addAdmin(response.data.user));
                }
            }
            store.dispatch(removeLoading());
            return response;
        }
    } catch (err) {
        throw err;
    }
};

export const company_check = async (company) => {
    try {
        const response = await sd_api_post("/api/v1/company", {
            company: company,
        });
        if (response.status == 200) {
            store.dispatch(removeLoading());
            return true;
        }
    } catch (err) {
        throw err;
    }
};

/**
 * logout
 */
export const logout = async () => {
    try {
        await sd_api_post("/api/v1/logout", {
            logout: true,
        });
        store.dispatch(addAuth(false));
        store.dispatch(addUser(false));
        store.dispatch(addAdmin(false));
        store.dispatch(removeMetadata());
        store.dispatch(addTeamdata(false));
        store.dispatch(addMspdata(false));
        store.dispatch(addTenantdata(false));
        store.dispatch(addMember(false));
        store.dispatch(removeLoading());
    } catch (err) {
        throw err;
    }
};

/**
 * logout if we arent authorised
 */
export const logout_unauthorized = async () => {
    try {
        store.dispatch(addAuth(false));
        store.dispatch(addUser(false));
        store.dispatch(addAdmin(false));
        store.dispatch(removeMetadata());
        store.dispatch(addTeamdata(false));
        store.dispatch(addMspdata(false));
        store.dispatch(addTenantdata(false));
        store.dispatch(addMember(false));
    } catch (err) {
        throw err;
    }
};

/**
 * onboard
 * @param {*} onboard
 * @returns
 */
export const onboarded = async (onboard) => {
    try {
        const response = await sd_api_post("/api/v1/onboard", {
            onboard: onboard,
        });

        store.dispatch(
            updateOnboard({
                onboarded: response.data.data,
                onboarded_at: new Date().toISOString(),
            })
        );
        store.dispatch(removeLoading());
        return response.data;
    } catch (err) {
        throw err;
    }
};

/**
 *
 * @param {*} user_id
 * @returns
 */
export const sessions = async (user_id) => {
    try {
        const response = await sd_api_post("/api/v1/sessions", {
            user_id: user_id,
        });
        store.dispatch(removeLoading());
        return response.data;
    } catch (err) {
        throw err;
    }
};

/**
 *
 * @param {*} token_id
 * @param {*} user_id
 * @returns
 */
export const session_delete = async (token_id, user_id) => {
    try {
        const response = await sd_api_post("/api/v1/session_delete", {
            token_id: token_id,
            user_id: user_id,
        });
        store.dispatch(removeLoading());
        return response.data;
    } catch (err) {
        throw err;
    }
};

/**
 *
 * @param {*} data
 * @param {*} tenant
 * @returns
 */
export const edit_user = async (data, tenant) => {
    try {
        const response = await sd_api_post(
            "/api/v1/editprofile",
            {
                name: data.email,
                email: data.email,
                notify_email: data.notify_email,
                notify_native: data.notify_native,
                notify_web: data.notify_web,
            },
            tenant
        );
        if (response.status == 200) {
            if (
                check_is_user(response.data.user) ||
                check_is_manager(response.data.user)
            ) {
                store.dispatch(editUser(response.data.user));
            }
            if (
                check_is_admin(response.data.user) ||
                check_is_msp(response.data.user)
            ) {
                store.dispatch(editAdmin(response.data.user));
            }
            store.dispatch(removeLoading());
            return response;
        }
    } catch (err) {
        throw err;
    }
};

/**
 *
 * @param {*} data
 * @returns
 */
export const edit_admin = async (data) => {
    try {
        const response = await sd_api_post_admin("/api/v1/editprofile", {
            name: data.email,
            email: data.email,
            notify_email: data.notify_email,
            notify_native: data.notify_native,
            notify_web: data.notify_web,
        });
        if (response.status == 200) {
            if (
                check_is_admin(response.data.user) ||
                check_is_msp(response.data.user)
            ) {
                store.dispatch(editAdmin(response.data.user));
            }
            store.dispatch(removeLoading());
            return response;
        }
    } catch (err) {
        throw err;
    }
};

/**
 *
 * @param {*} email
 * @param {*} tenant
 * @returns
 */
export const forgotpassword = async (email, tenant) => {
    try {
        const response = await sd_api_post(
            "/api/v1/forgot-password",
            {
                email: email,
            },
            tenant
        );
        if (response.status == 200) {
            store.dispatch(removeLoading());
            return true;
        } else {
            return false;
        }
    } catch (err) {
        throw err;
    }
};

/**
 *
 * @param {*} email
 * @returns
 */
export const forgotpasswordadmin = async (email) => {
    try {
        const response = await sd_api_post_admin("/api/v1/forgot-password", {
            email: email,
        });
        if (response.status == 200) {
            store.dispatch(removeLoading());
            return true;
        } else {
            return false;
        }
    } catch (err) {
        throw err;
    }
};

/**
 *
 * @param {*} email
 * @returns
 */
export const forgot_member_password = async (email) => {
    try {
        const response = await sd_api_post("/api/v1/forgot-password", {
            email: email,
        });
        if (response.status == 200) {
            store.dispatch(removeLoading());
            return true;
        } else {
            return false;
        }
    } catch (err) {
        throw err;
    }
};

/**
 *
 * @param {*} email
 * @returns
 */
export const invite_member_notification = async (email) => {
    try {
        const response = await sd_api_post("/api/v1/member/invite", {
            email: email,
        });
        if (response.status == 200) {
            store.dispatch(removeLoading());
            return true;
        } else {
            return false;
        }
    } catch (err) {
        throw err;
    }
};

/**
 *
 * @param {*} email
 * @param {*} password
 * @param {*} password_confirmation
 * @param {*} token
 * @param {*} tenant
 * @returns
 */
export const resetpassword = async (
    email,
    password,
    password_confirmation,
    token,
    tenant
) => {
    try {
        const response = await sd_api_post(
            "/api/v1/reset-password",
            {
                email: email,
                password: password,
                password_confirmation: password_confirmation,
                token: token,
                device_name: Capacitor.getPlatform(),
            },
            // on this one I need to set the tenant header
            tenant
        );
        if (response.status == 200) {
            if (response.data.two_factor) {
                store.dispatch(addAuth(response.data));
            } else {
                store.dispatch(addAuth(response.data.auth));

                if (
                    check_is_user(response.data.user) ||
                    check_is_manager(response.data.user)
                ) {
                    store.dispatch(addUser(response.data.user));
                }
                if (
                    check_is_admin(response.data.user) ||
                    check_is_msp(response.data.user)
                ) {
                    store.dispatch(addAdmin(response.data.user));
                }
            }
            store.dispatch(removeLoading());
            return true;
        }
    } catch (err) {
        throw err;
    }
};

/**
 *
 * @param {*} user
 * @returns
 */
export const check_is_admin = (user) => {
    const roles = user.roles;
    if (
        roles != undefined &&
        roles.findIndex((element) => element.name == "admin") >= 0
    ) {
        return user.id;
    } else {
        return false;
    }
};

/**
 *
 * @param {*} user
 * @returns
 */
export const check_is_msp = (user) => {
    const roles = user.roles;
    if (
        roles != undefined &&
        roles.findIndex((element) => element.name == "msp") >= 0
    ) {
        return user.id;
    } else {
        return false;
    }
};

/**
 *
 * @param {*} user
 * @returns
 */
export const check_is_manager = (user) => {
    const roles = user.roles;
    if (
        roles != undefined &&
        roles.findIndex((element) => element.name == "manager") >= 0
    ) {
        return user.id;
    } else {
        return false;
    }
};

/**
 *
 * @param {*} user
 * @returns
 */
export const check_is_user = (user) => {
    const roles = user.roles;
    if (
        roles != undefined &&
        roles.findIndex((element) => element.name == "user") >= 0
    ) {
        return user.id;
    } else {
        return false;
    }
};

/**
 *
 * @returns
 */
export const is_admin = () => {
    const redux = store.getState();
    if (!check_is_admin(redux.admin)) {
        return false;
    } else {
        return true;
    }
};

/**
 *
 * @returns
 */
export const is_msp = () => {
    const redux = store.getState();
    if (!check_is_msp(redux.admin)) {
        return false;
    } else {
        return true;
    }
};

/**
 *
 * @returns
 */
export const is_manager = () => {
    const redux = store.getState();
    if (!check_is_manager(redux.user)) {
        return false;
    } else {
        return true;
    }
};

/**
 *
 * @returns
 */
export const is_user = () => {
    const redux = store.getState();
    if (!check_is_user(redux.user)) {
        return false;
    } else {
        return true;
    }
};

/**
 *
 * @returns
 */
export const is_admin_id = () => {
    const redux = store.getState();
    return check_is_admin(redux.admin);
};

/**
 *
 * @returns
 */
export const is_msp_id = () => {
    const redux = store.getState();
    return check_is_msp(redux.admin);
};

/**
 *
 * @returns
 */
export const is_manager_id = () => {
    const redux = store.getState();
    return check_is_manager(redux.user);
};

/**
 *
 * @returns
 */
export const is_user_id = () => {
    const redux = store.getState();
    return check_is_user(redux.user);
};

/**
 *
 * @returns
 */
export const is_logged_in = () => {
    const redux = store.getState();
    return redux.auth && (is_user() || is_manager() || is_msp() || is_admin());
};

/**
 *
 * @returns
 */
export const is_impersonate_member = () => {
    const redux = store.getState();
    if (redux?.auth?.member?.id && (is_manager() || is_msp() || is_admin())) {
        return true;
    } else {
        return false;
    }
};

/**
 *
 * @returns
 */
export const is_impersonate_member_id = () => {
    const redux = store.getState();
    if (redux?.auth?.member?.id && (is_manager() || is_msp() || is_admin())) {
        return redux?.auth?.member?.id;
    } else {
        return false;
    }
};

/**
 *
 * @returns
 */
export const is_impersonate_member_email = () => {
    const redux = store.getState();
    if (
        redux?.auth?.member?.email &&
        (is_manager() || is_msp() || is_admin())
    ) {
        return redux?.auth?.member?.email;
    } else {
        return false;
    }
};

/**
 *
 * @returns
 */
export const is_impersonate_msp = () => {
    const redux = store.getState();
    if (redux?.auth?.msp?.id && is_admin()) {
        return redux?.auth?.msp?.id;
    } else {
        return false;
    }
};

/**
 *
 * @returns
 */
export const is_impersonate_tenant = () => {
    const redux = store.getState();
    if (redux?.auth?.impersonate?.tenant_id && (is_msp() || is_admin())) {
        return redux?.auth?.impersonate?.tenant_id;
    } else {
        return false;
    }
};

/**
 *
 * @returns
 */
export const msp_max_users = () => {
    const redux = store.getState();
    if (redux.auth?.msp?.msp_max_users && (is_msp() || is_admin())) {
        return redux.auth?.msp?.msp_max_users;
    } else {
        return false;
    }
};

/**
 *
 * @returns
 */
export const msp_total_users = () => {
    const redux = store.getState();
    if (redux.auth?.msp?.msp_total_users && (is_msp() || is_admin())) {
        return redux.auth?.msp?.msp_total_users;
    } else {
        return false;
    }
};

/**
 *
 * @returns
 */
export const msp_used_users = () => {
    const redux = store.getState();
    if (redux.auth?.msp?.msp_used_users && (is_msp() || is_admin())) {
        return redux.auth?.msp?.msp_used_users;
    } else {
        return false;
    }
};

/**
 *
 * @returns
 */
export const msp_onboarded_users = () => {
    const redux = store.getState();
    if (redux.auth?.msp?.msp_onboarded_users && (is_msp() || is_admin())) {
        return redux.auth?.msp?.msp_onboarded_users;
    } else {
        return false;
    }
};

/**
 *
 * @returns
 */
export const tenant_max_users = () => {
    const redux = store.getState();
    if (
        redux.auth?.tenant?.max_users &&
        (is_manager() || is_msp() || is_admin())
    ) {
        return redux.auth?.tenant?.max_users;
    } else {
        return false;
    }
};

/**
 *
 * @returns
 */
export const tenant_total_users = () => {
    const redux = store.getState();
    if (
        redux.auth?.tenant?.total_users &&
        (is_manager() || is_msp() || is_admin())
    ) {
        return redux.auth?.tenant?.total_users;
    } else {
        return false;
    }
};

/**
 *
 * @returns
 */
export const tenant_onboarded_users = () => {
    const redux = store.getState();
    if (
        redux.auth?.tenant?.onboarded_users &&
        (is_manager() || is_msp() || is_admin())
    ) {
        return redux.auth?.tenant?.onboarded_users;
    } else {
        return false;
    }
};

/**
 *
 * @returns
 */
export const get_user_id = () => {
    if (is_admin()) return is_admin_id();
    if (is_msp()) return is_msp_id();
    if (is_manager()) return is_manager_id();
    if (is_user()) return is_user_id();
};

export const amazon_redirect = async () => {
    try {
        const response = await sd_api_get("/api/v1/amazon/redirect");
        if (response.status == 200) {
            if (response?.data?.data) {
                // Redirect the user to Amazon OAuth page
                window.location.href = response?.data?.data;
            } else {
                alert("Failed to get Amazon login URL.");
            }
        }
    } catch (err) {
        console.error(err);
    }
};

export const amazon_callback = async (code, scope) => {
    try {
        const response = await sd_api_post("/api/v1/amazon/callback", {
            code: code,
            scope: scope,
        });
        if (response.status == 200) {
            if (response?.data?.data) {
                // Redirect the user to Amazon OAuth page
                store.dispatch(addUser(response?.data?.data));
            } else {
                alert("Failed to get Amazon login URL.");
            }
        }
    } catch (err) {
        console.error(err);
    }
};

import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
    name: "admin",
    initialState: false,
    reducers: {
        addAdmin: (state, action) => {
            return action.payload;
        },
        removeAdmin: (state, action) => {
            return false;
        },
        editAdmin: (state, action) => {
            return {
                ...state,
                name: action.payload.name,
                email: action.payload.email,
                notify_email: action.payload.notify_email,
                notify_native: action.payload.notify_native,
            };
        },
        updateToken: (state, action) => {
            // see if we already have an entry in our user store
            state.token = action.payload.token;
        },
        updateOnboard: (state, action) => {
            // see if we already have an entry in our user store
            (state.onboarded = action.payload.onboarded),
                (state.onboarded_at = action.payload.onboarded_at);
        },
    },
});

export const adminReducer = adminSlice.reducer;

export const { addAdmin, removeAdmin, editAdmin, updateToken, updateOnboard } =
    adminSlice.actions;

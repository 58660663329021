import { createSlice } from "@reduxjs/toolkit";

const teamoverviewSlice = createSlice({
    name: "teamoverview",
    initialState: false,
    reducers: {
        addOverview: (state, action) => {
            return action.payload;
        },
        removeOverview: (state, action) => {
            return false;
        },
    },
});

export const teamoverviewReducer = teamoverviewSlice.reducer;

export const { addOverview, removeOverview } = teamoverviewSlice.actions;

import { createSlice } from "@reduxjs/toolkit";

const overviewSlice = createSlice({
    name: "overview",
    initialState: false,
    reducers: {
        addOverview: (state, action) => {
            return action.payload;
        },
        removeOverview: (state, action) => {
            return false;
        },
    },
});

export const overviewReducer = overviewSlice.reducer;

export const { addOverview, removeOverview } = overviewSlice.actions;

import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { NavigationBar } from "@hugotomazi/capacitor-navigation-bar";

export const set_statusbar = (theme) => {
    const platform = Capacitor.getPlatform();

    if (platform == "android") {
        // StatusBar.setOverlaysWebView({ overlay: true });
        NavigationBar.setTransparency({ isTransparent: true });
        // set the statusbar to be transparent
        StatusBar.setBackgroundColor({ color: "#ffffff00" }).catch(() => {});
        if (theme == "dark" || theme == "index") {
            // these are switched as we have opposite coloured menu
            StatusBar.setStyle({ style: Style.Dark }).catch(() => {});
        } else if (theme == "light" || theme == "login") {
            // these are switched as we have opposite coloured menu
            StatusBar.setStyle({ style: Style.Light }).catch(() => {});
        }
    }
    if (platform == "ios") {
        if (theme == "dark" || theme == "index") {
            // these are switched as we have opposite coloured menu
            StatusBar.setStyle({ style: Style.Dark }).catch(() => {});
        } else if (theme == "light" || theme == "login") {
            // these are switched as we have opposite coloured menu
            StatusBar.setStyle({ style: Style.Light }).catch(() => {});
        }
    }
};

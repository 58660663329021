import { createSlice } from "@reduxjs/toolkit";

const paginateSlice = createSlice({
    name: "paginate",
    initialState: {
        pagecount: 1,
        sort: "new",
        itemcount: 10,
        device_filter: ["all"],
        vuln_filter: ["all"],
        table_view: false,
        search_term: false,
        hw: false,
        os: false,
    },
    reducers: {
        setPaginate: (state, action) => {
            return action.payload;
        },
        clearPaginate: (state, action) => {
            return {
                pagecount: 1,
                sort: "new",
                itemcount: 10,
                device_filter: ["all"],
                vuln_filter: ["all"],
                table_view: false,
                search_term: false,
                hw: false,
                os: false,
            };
        },

        pagecount: (state, action) => {
            return {
                ...state,
                pagecount: action.payload,
            };
        },
        sort: (state, action) => {
            return {
                ...state,
                sort: action.payload,
            };
        },
        itemcount: (state, action) => {
            return {
                ...state,
                pagecount: 1,
                itemcount: action.payload,
            };
        },
        device_filter: (state, action) => {
            return {
                ...state,
                device_filter: action.payload,
            };
        },
        vuln_filter: (state, action) => {
            return {
                ...state,
                vuln_filter: action.payload,
            };
        },
        table_view: (state, action) => {
            return {
                ...state,
                table_view: action.payload,
            };
        },
        search_term: (state, action) => {
            return {
                ...state,
                search_term: action.payload,
            };
        },
        hw: (state, action) => {
            return {
                ...state,
                hw: action.payload,
            };
        },
        os: (state, action) => {
            return {
                ...state,
                os: action.payload,
            };
        },
    },
});

export const paginateReducer = paginateSlice.reducer;

export const {
    setPaginate,
    clearPaginate,
    pagecount,
    sort,
    itemcount,
    device_filter,
    vuln_filter,
    table_view,
    search_term,
    hw,
    os,
} = paginateSlice.actions;

import { createSlice } from "@reduxjs/toolkit";

const metadataSlice = createSlice({
    name: "metadata",
    initialState: false,
    reducers: {
        addMetadata: (state, action) => {
            return action.payload;
        },
        editMetadata: (state, action) => {
            const index = state?.devicedata?.findIndex(
                (item) => item.id == action.payload.id
            );

            if (index >= 0) {
                state.devicedata[index] = action.payload;
            }
        },
        editMetaVulndata: (state, action) => {
            // delete all for that mac address and metadata id, then add the new stuff to the end, but only for theis sub-prop
            state.vulndata =
                state?.vulndata
                    ?.filter(
                        (element) =>
                            element.device_data_id !=
                            action.payload.device_data_id
                    )
                    .concat(action.payload.vulndata) ?? false;
        },
        editSingleVulndata: (state, action) => {
            if (state?.vulndata) {
                // delete all for that mac address and metadata id, then add the new stuff to the end, but only for theis sub-prop
                const index = state?.vulndata?.findIndex(
                    (item) =>
                        item.cve_meta_id == action.payload.cve_meta_id &&
                        item.device_data_id == action.payload.device_data_id &&
                        item.metadata_id == action.payload.metadata_id
                );

                if (index >= 0) {
                    (state.vulndata[index].ignored = action.payload.ignored),
                        (state.vulndata[index].resolved =
                            action.payload.resolved),
                        (state.vulndata[index].comment =
                            action.payload.comment);
                }
            }
            return state;
        },
        removeMetadata: (state, action) => {
            return false;
        },
    },
});

export const metadataReducer = metadataSlice.reducer;

export const {
    addMetadata,
    editMetadata,
    editMetaVulndata,
    editSingleVulndata,
    removeMetadata,
} = metadataSlice.actions;

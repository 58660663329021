import { createSlice } from "@reduxjs/toolkit";

const notificationsSlice = createSlice({
    name: "notifications",
    initialState: false,
    reducers: {
        addNotifications: (state, action) => {
            return action.payload;
        },
        editNotification: (state, action) => {
            const index = state.findIndex(
                (item) => item.id == action.payload.id
            );

            if (index >= 0) {
                state[index] = action.payload;
            }
        },
        deleteNotification: (state, action) => {
            return state.filter((item) => item.id != action.payload.id);
        },
        removeNotifications: (state, action) => {
            return false;
        },
    },
});

export const notificationsReducer = notificationsSlice.reducer;

export const {
    addNotifications,
    editNotification,
    deleteNotification,
    removeNotifications,
} = notificationsSlice.actions;
